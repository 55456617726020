import { default as _404A0xLGFdxDXMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/404.vue?macro=true";
import { default as about_45us_45csinAPoScec5Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/about-us-cs.vue?macro=true";
import { default as about_45us_45docwqyCK36vPIMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/about-us-doc.vue?macro=true";
import { default as about_45us_45eventIJjP7hJVSRMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/about-us-event.vue?macro=true";
import { default as about_45us_45managementdwx6oN7rB0Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/about-us-management.vue?macro=true";
import { default as appointment_45scheduleoFZnEDoHSRMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/appointment-schedule.vue?macro=true";
import { default as dynamic_45detailsrLC0wAm41hMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/blog-details/dynamic-details.vue?macro=true";
import { default as indexUBfbZ4YnHMMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/blog-details/index.vue?macro=true";
import { default as blog_45v1eU0A2CCBqjMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/blog-v1.vue?macro=true";
import { default as blog_45v2Kb5qnLtY63Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/blog-v2.vue?macro=true";
import { default as blog_45v3vDEKQgfFqiMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/blog-v3.vue?macro=true";
import { default as blog_45v4nh77adgzsfMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/blog-v4.vue?macro=true";
import { default as cgvQhgQHDQ1CeMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/cgv.vue?macro=true";
import { default as changelogCoflpwVWbKMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/changelog.vue?macro=true";
import { default as coming_45soon3GmDW2BzP6Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/coming-soon.vue?macro=true";
import { default as contact_45us_45cssxlVP94n4nMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/contact-us-cs.vue?macro=true";
import { default as contact_45us_45docdGZYWzYJaNMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/contact-us-doc.vue?macro=true";
import { default as contact_45us_45event2G7hsVl7s7Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/contact-us-event.vue?macro=true";
import { default as contact_45us_45managementEcrwlLmrd8Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/contact-us-management.vue?macro=true";
import { default as customer_45supportz3htIGYLd7Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/customer-support.vue?macro=true";
import { default as _91id_93FQvrvQI7FNMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/detail-produit/[idMenu]/[idSubMenu]/[id].vue?macro=true";
import { default as _91idRealisation_93DNEHzxEcKhMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/detail-realisation/[titleRealisation]/[idRealisation].vue?macro=true";
import { default as doc_45box_45banner7ZJGkQ7yjiMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/doc-box-banner.vue?macro=true";
import { default as doc_45boxqMzSdLky8sMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/doc-box.vue?macro=true";
import { default as doc_45full_45width_45banner5jGJpb3T9EMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/doc-full-width-banner.vue?macro=true";
import { default as doc_45fullwidthIrAc4XavotMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/doc-fullwidth.vue?macro=true";
import { default as doc_45landingnWCxXMFR81Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/doc-landing.vue?macro=true";
import { default as doc_45signatureEQZ84NKWvvMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/doc-signature.vue?macro=true";
import { default as indexEG9WR2F2BlMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/entreprise/index.vue?macro=true";
import { default as eventX5Eagom5EiMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/event.vue?macro=true";
import { default as dynamic_45detailsitU23bJmRaMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/faq-details/dynamic-details.vue?macro=true";
import { default as indexulkcS9Xn5GMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/faq-details/index.vue?macro=true";
import { default as faq_45paget5eJEzhVg6Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/faq-page.vue?macro=true";
import { default as feature_45csflBNQjLbPVMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/feature-cs.vue?macro=true";
import { default as form_45surveyDOmkYhbFJ3Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/form-survey.vue?macro=true";
import { default as indexS4wCkOLP1gMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/home/index.vue?macro=true";
import { default as indexTQT7JJ2DrPMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/index.vue?macro=true";
import { default as indexFIZe6AGUNFMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/liste-realisation/index.vue?macro=true";
import { default as _91id_93qlbUFD58DXMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/listes-produits/[idMenu]/[idSubMenu]/[id].vue?macro=true";
import { default as login0YQdZl3ZkwMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/login.vue?macro=true";
import { default as mentions_45legalesWxa0eGVjbRMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/mentions-legales.vue?macro=true";
import { default as mobile_45app9yo4e6HQ5wMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/mobile-app.vue?macro=true";
import { default as _91idSlug_93785FJH4UEvMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/nos-domaines/[slug]/[idSlug].vue?macro=true";
import { default as note_45takingCzvdPosJPpMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/note-taking.vue?macro=true";
import { default as nous_45contacterAKqWNligoOMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/nous-contacter.vue?macro=true";
import { default as dynamic_45detailsWcxwQ3IIDwMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/portfolio-details/dynamic-details.vue?macro=true";
import { default as indexrSHqSWQYKpMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/portfolio-details/index.vue?macro=true";
import { default as portfolio_45v1l27TdE3fb3Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v1.vue?macro=true";
import { default as portfolio_45v25VvsgAbYF6Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v2.vue?macro=true";
import { default as portfolio_45v3fbxNItJfvtMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v3.vue?macro=true";
import { default as portfolio_45v4wJSbZDBOEQMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v4.vue?macro=true";
import { default as portfolio_45v5gzTeLvNEyjMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v5.vue?macro=true";
import { default as pricing_45csNu4gBT8ntwMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/pricing-cs.vue?macro=true";
import { default as pricing_45eventLQ4yO5s868Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/pricing-event.vue?macro=true";
import { default as pricing_45managementmtOFifUZtyMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/pricing-management.vue?macro=true";
import { default as product_45csqBc5KKaciaMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/product-cs.vue?macro=true";
import { default as product_45landing_45darkq2Kcckcg5HMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/product-landing-dark.vue?macro=true";
import { default as product_45landing3vPB71CDAVMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/product-landing.vue?macro=true";
import { default as indexDoca8xJrUdMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/savoir-faire/index.vue?macro=true";
import { default as dynamic_45detailsFkGfcIiu4VMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/service-details/dynamic-details.vue?macro=true";
import { default as index5BndF7fKLCMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/service-details/index.vue?macro=true";
import { default as services_45v1vyhHjmLLSZMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/services-v1.vue?macro=true";
import { default as services_45v2ysQhYDxv8iMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/services-v2.vue?macro=true";
import { default as services_45v3iHlRfBJjGNMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/services-v3.vue?macro=true";
import { default as services_45v4P9gWNscdd2Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/services-v4.vue?macro=true";
import { default as sign_45up6BbquDvgkjMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/sign-up.vue?macro=true";
import { default as solution_45managementooMRDxVKTGMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/solution-management.vue?macro=true";
import { default as dynamic_45detailsAnzZQAysbCMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/team-details/dynamic-details.vue?macro=true";
import { default as indexZyb0Xrd26WMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/team-details/index.vue?macro=true";
import { default as team_45v1yHtBjLYoclMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/team-v1.vue?macro=true";
import { default as team_45v2WIDAPPhrOMMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/team-v2.vue?macro=true";
import { default as terms_45conditionOBujsoU9NJMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/terms-condition.vue?macro=true";
import { default as video_45editorPemdMG9NlZMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/video-editor.vue?macro=true";
import { default as vr_45landingj2CiTNmgL5Meta } from "C:/Users/HP/WebstormProjects/Altela/pages/vr-landing.vue?macro=true";
import { default as website_45builderFE4FzeE9ckMeta } from "C:/Users/HP/WebstormProjects/Altela/pages/website-builder.vue?macro=true";
export default [
  {
    name: _404A0xLGFdxDXMeta?.name ?? "404",
    path: _404A0xLGFdxDXMeta?.path ?? "/404",
    meta: _404A0xLGFdxDXMeta || {},
    alias: _404A0xLGFdxDXMeta?.alias || [],
    redirect: _404A0xLGFdxDXMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/404.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45csinAPoScec5Meta?.name ?? "about-us-cs",
    path: about_45us_45csinAPoScec5Meta?.path ?? "/about-us-cs",
    meta: about_45us_45csinAPoScec5Meta || {},
    alias: about_45us_45csinAPoScec5Meta?.alias || [],
    redirect: about_45us_45csinAPoScec5Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/about-us-cs.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45docwqyCK36vPIMeta?.name ?? "about-us-doc",
    path: about_45us_45docwqyCK36vPIMeta?.path ?? "/about-us-doc",
    meta: about_45us_45docwqyCK36vPIMeta || {},
    alias: about_45us_45docwqyCK36vPIMeta?.alias || [],
    redirect: about_45us_45docwqyCK36vPIMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/about-us-doc.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45eventIJjP7hJVSRMeta?.name ?? "about-us-event",
    path: about_45us_45eventIJjP7hJVSRMeta?.path ?? "/about-us-event",
    meta: about_45us_45eventIJjP7hJVSRMeta || {},
    alias: about_45us_45eventIJjP7hJVSRMeta?.alias || [],
    redirect: about_45us_45eventIJjP7hJVSRMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/about-us-event.vue").then(m => m.default || m)
  },
  {
    name: about_45us_45managementdwx6oN7rB0Meta?.name ?? "about-us-management",
    path: about_45us_45managementdwx6oN7rB0Meta?.path ?? "/about-us-management",
    meta: about_45us_45managementdwx6oN7rB0Meta || {},
    alias: about_45us_45managementdwx6oN7rB0Meta?.alias || [],
    redirect: about_45us_45managementdwx6oN7rB0Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/about-us-management.vue").then(m => m.default || m)
  },
  {
    name: appointment_45scheduleoFZnEDoHSRMeta?.name ?? "appointment-schedule",
    path: appointment_45scheduleoFZnEDoHSRMeta?.path ?? "/appointment-schedule",
    meta: appointment_45scheduleoFZnEDoHSRMeta || {},
    alias: appointment_45scheduleoFZnEDoHSRMeta?.alias || [],
    redirect: appointment_45scheduleoFZnEDoHSRMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/appointment-schedule.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsrLC0wAm41hMeta?.name ?? "blog-details-dynamic-details",
    path: dynamic_45detailsrLC0wAm41hMeta?.path ?? "/blog-details/dynamic-details",
    meta: dynamic_45detailsrLC0wAm41hMeta || {},
    alias: dynamic_45detailsrLC0wAm41hMeta?.alias || [],
    redirect: dynamic_45detailsrLC0wAm41hMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/blog-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexUBfbZ4YnHMMeta?.name ?? "blog-details",
    path: indexUBfbZ4YnHMMeta?.path ?? "/blog-details",
    meta: indexUBfbZ4YnHMMeta || {},
    alias: indexUBfbZ4YnHMMeta?.alias || [],
    redirect: indexUBfbZ4YnHMMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/blog-details/index.vue").then(m => m.default || m)
  },
  {
    name: blog_45v1eU0A2CCBqjMeta?.name ?? "blog-v1",
    path: blog_45v1eU0A2CCBqjMeta?.path ?? "/blog-v1",
    meta: blog_45v1eU0A2CCBqjMeta || {},
    alias: blog_45v1eU0A2CCBqjMeta?.alias || [],
    redirect: blog_45v1eU0A2CCBqjMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/blog-v1.vue").then(m => m.default || m)
  },
  {
    name: blog_45v2Kb5qnLtY63Meta?.name ?? "blog-v2",
    path: blog_45v2Kb5qnLtY63Meta?.path ?? "/blog-v2",
    meta: blog_45v2Kb5qnLtY63Meta || {},
    alias: blog_45v2Kb5qnLtY63Meta?.alias || [],
    redirect: blog_45v2Kb5qnLtY63Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/blog-v2.vue").then(m => m.default || m)
  },
  {
    name: blog_45v3vDEKQgfFqiMeta?.name ?? "blog-v3",
    path: blog_45v3vDEKQgfFqiMeta?.path ?? "/blog-v3",
    meta: blog_45v3vDEKQgfFqiMeta || {},
    alias: blog_45v3vDEKQgfFqiMeta?.alias || [],
    redirect: blog_45v3vDEKQgfFqiMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/blog-v3.vue").then(m => m.default || m)
  },
  {
    name: blog_45v4nh77adgzsfMeta?.name ?? "blog-v4",
    path: blog_45v4nh77adgzsfMeta?.path ?? "/blog-v4",
    meta: blog_45v4nh77adgzsfMeta || {},
    alias: blog_45v4nh77adgzsfMeta?.alias || [],
    redirect: blog_45v4nh77adgzsfMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/blog-v4.vue").then(m => m.default || m)
  },
  {
    name: cgvQhgQHDQ1CeMeta?.name ?? "cgv",
    path: cgvQhgQHDQ1CeMeta?.path ?? "/cgv",
    meta: cgvQhgQHDQ1CeMeta || {},
    alias: cgvQhgQHDQ1CeMeta?.alias || [],
    redirect: cgvQhgQHDQ1CeMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/cgv.vue").then(m => m.default || m)
  },
  {
    name: changelogCoflpwVWbKMeta?.name ?? "changelog",
    path: changelogCoflpwVWbKMeta?.path ?? "/changelog",
    meta: changelogCoflpwVWbKMeta || {},
    alias: changelogCoflpwVWbKMeta?.alias || [],
    redirect: changelogCoflpwVWbKMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: coming_45soon3GmDW2BzP6Meta?.name ?? "coming-soon",
    path: coming_45soon3GmDW2BzP6Meta?.path ?? "/coming-soon",
    meta: coming_45soon3GmDW2BzP6Meta || {},
    alias: coming_45soon3GmDW2BzP6Meta?.alias || [],
    redirect: coming_45soon3GmDW2BzP6Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/coming-soon.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45cssxlVP94n4nMeta?.name ?? "contact-us-cs",
    path: contact_45us_45cssxlVP94n4nMeta?.path ?? "/contact-us-cs",
    meta: contact_45us_45cssxlVP94n4nMeta || {},
    alias: contact_45us_45cssxlVP94n4nMeta?.alias || [],
    redirect: contact_45us_45cssxlVP94n4nMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/contact-us-cs.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45docdGZYWzYJaNMeta?.name ?? "contact-us-doc",
    path: contact_45us_45docdGZYWzYJaNMeta?.path ?? "/contact-us-doc",
    meta: contact_45us_45docdGZYWzYJaNMeta || {},
    alias: contact_45us_45docdGZYWzYJaNMeta?.alias || [],
    redirect: contact_45us_45docdGZYWzYJaNMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/contact-us-doc.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45event2G7hsVl7s7Meta?.name ?? "contact-us-event",
    path: contact_45us_45event2G7hsVl7s7Meta?.path ?? "/contact-us-event",
    meta: contact_45us_45event2G7hsVl7s7Meta || {},
    alias: contact_45us_45event2G7hsVl7s7Meta?.alias || [],
    redirect: contact_45us_45event2G7hsVl7s7Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/contact-us-event.vue").then(m => m.default || m)
  },
  {
    name: contact_45us_45managementEcrwlLmrd8Meta?.name ?? "contact-us-management",
    path: contact_45us_45managementEcrwlLmrd8Meta?.path ?? "/contact-us-management",
    meta: contact_45us_45managementEcrwlLmrd8Meta || {},
    alias: contact_45us_45managementEcrwlLmrd8Meta?.alias || [],
    redirect: contact_45us_45managementEcrwlLmrd8Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/contact-us-management.vue").then(m => m.default || m)
  },
  {
    name: customer_45supportz3htIGYLd7Meta?.name ?? "customer-support",
    path: customer_45supportz3htIGYLd7Meta?.path ?? "/customer-support",
    meta: customer_45supportz3htIGYLd7Meta || {},
    alias: customer_45supportz3htIGYLd7Meta?.alias || [],
    redirect: customer_45supportz3htIGYLd7Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/customer-support.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FQvrvQI7FNMeta?.name ?? "detail-produit-idMenu-idSubMenu-id",
    path: _91id_93FQvrvQI7FNMeta?.path ?? "/detail-produit/:idMenu()/:idSubMenu()/:id()",
    meta: _91id_93FQvrvQI7FNMeta || {},
    alias: _91id_93FQvrvQI7FNMeta?.alias || [],
    redirect: _91id_93FQvrvQI7FNMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/detail-produit/[idMenu]/[idSubMenu]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91idRealisation_93DNEHzxEcKhMeta?.name ?? "detail-realisation-titleRealisation-idRealisation",
    path: _91idRealisation_93DNEHzxEcKhMeta?.path ?? "/detail-realisation/:titleRealisation()/:idRealisation()",
    meta: _91idRealisation_93DNEHzxEcKhMeta || {},
    alias: _91idRealisation_93DNEHzxEcKhMeta?.alias || [],
    redirect: _91idRealisation_93DNEHzxEcKhMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/detail-realisation/[titleRealisation]/[idRealisation].vue").then(m => m.default || m)
  },
  {
    name: doc_45box_45banner7ZJGkQ7yjiMeta?.name ?? "doc-box-banner",
    path: doc_45box_45banner7ZJGkQ7yjiMeta?.path ?? "/doc-box-banner",
    meta: doc_45box_45banner7ZJGkQ7yjiMeta || {},
    alias: doc_45box_45banner7ZJGkQ7yjiMeta?.alias || [],
    redirect: doc_45box_45banner7ZJGkQ7yjiMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/doc-box-banner.vue").then(m => m.default || m)
  },
  {
    name: doc_45boxqMzSdLky8sMeta?.name ?? "doc-box",
    path: doc_45boxqMzSdLky8sMeta?.path ?? "/doc-box",
    meta: doc_45boxqMzSdLky8sMeta || {},
    alias: doc_45boxqMzSdLky8sMeta?.alias || [],
    redirect: doc_45boxqMzSdLky8sMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/doc-box.vue").then(m => m.default || m)
  },
  {
    name: doc_45full_45width_45banner5jGJpb3T9EMeta?.name ?? "doc-full-width-banner",
    path: doc_45full_45width_45banner5jGJpb3T9EMeta?.path ?? "/doc-full-width-banner",
    meta: doc_45full_45width_45banner5jGJpb3T9EMeta || {},
    alias: doc_45full_45width_45banner5jGJpb3T9EMeta?.alias || [],
    redirect: doc_45full_45width_45banner5jGJpb3T9EMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/doc-full-width-banner.vue").then(m => m.default || m)
  },
  {
    name: doc_45fullwidthIrAc4XavotMeta?.name ?? "doc-fullwidth",
    path: doc_45fullwidthIrAc4XavotMeta?.path ?? "/doc-fullwidth",
    meta: doc_45fullwidthIrAc4XavotMeta || {},
    alias: doc_45fullwidthIrAc4XavotMeta?.alias || [],
    redirect: doc_45fullwidthIrAc4XavotMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/doc-fullwidth.vue").then(m => m.default || m)
  },
  {
    name: doc_45landingnWCxXMFR81Meta?.name ?? "doc-landing",
    path: doc_45landingnWCxXMFR81Meta?.path ?? "/doc-landing",
    meta: doc_45landingnWCxXMFR81Meta || {},
    alias: doc_45landingnWCxXMFR81Meta?.alias || [],
    redirect: doc_45landingnWCxXMFR81Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/doc-landing.vue").then(m => m.default || m)
  },
  {
    name: doc_45signatureEQZ84NKWvvMeta?.name ?? "doc-signature",
    path: doc_45signatureEQZ84NKWvvMeta?.path ?? "/doc-signature",
    meta: doc_45signatureEQZ84NKWvvMeta || {},
    alias: doc_45signatureEQZ84NKWvvMeta?.alias || [],
    redirect: doc_45signatureEQZ84NKWvvMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/doc-signature.vue").then(m => m.default || m)
  },
  {
    name: indexEG9WR2F2BlMeta?.name ?? "entreprise",
    path: indexEG9WR2F2BlMeta?.path ?? "/entreprise",
    meta: indexEG9WR2F2BlMeta || {},
    alias: indexEG9WR2F2BlMeta?.alias || [],
    redirect: indexEG9WR2F2BlMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/entreprise/index.vue").then(m => m.default || m)
  },
  {
    name: eventX5Eagom5EiMeta?.name ?? "event",
    path: eventX5Eagom5EiMeta?.path ?? "/event",
    meta: eventX5Eagom5EiMeta || {},
    alias: eventX5Eagom5EiMeta?.alias || [],
    redirect: eventX5Eagom5EiMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/event.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsitU23bJmRaMeta?.name ?? "faq-details-dynamic-details",
    path: dynamic_45detailsitU23bJmRaMeta?.path ?? "/faq-details/dynamic-details",
    meta: dynamic_45detailsitU23bJmRaMeta || {},
    alias: dynamic_45detailsitU23bJmRaMeta?.alias || [],
    redirect: dynamic_45detailsitU23bJmRaMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/faq-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexulkcS9Xn5GMeta?.name ?? "faq-details",
    path: indexulkcS9Xn5GMeta?.path ?? "/faq-details",
    meta: indexulkcS9Xn5GMeta || {},
    alias: indexulkcS9Xn5GMeta?.alias || [],
    redirect: indexulkcS9Xn5GMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/faq-details/index.vue").then(m => m.default || m)
  },
  {
    name: faq_45paget5eJEzhVg6Meta?.name ?? "faq-page",
    path: faq_45paget5eJEzhVg6Meta?.path ?? "/faq-page",
    meta: faq_45paget5eJEzhVg6Meta || {},
    alias: faq_45paget5eJEzhVg6Meta?.alias || [],
    redirect: faq_45paget5eJEzhVg6Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/faq-page.vue").then(m => m.default || m)
  },
  {
    name: feature_45csflBNQjLbPVMeta?.name ?? "feature-cs",
    path: feature_45csflBNQjLbPVMeta?.path ?? "/feature-cs",
    meta: feature_45csflBNQjLbPVMeta || {},
    alias: feature_45csflBNQjLbPVMeta?.alias || [],
    redirect: feature_45csflBNQjLbPVMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/feature-cs.vue").then(m => m.default || m)
  },
  {
    name: form_45surveyDOmkYhbFJ3Meta?.name ?? "form-survey",
    path: form_45surveyDOmkYhbFJ3Meta?.path ?? "/form-survey",
    meta: form_45surveyDOmkYhbFJ3Meta || {},
    alias: form_45surveyDOmkYhbFJ3Meta?.alias || [],
    redirect: form_45surveyDOmkYhbFJ3Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/form-survey.vue").then(m => m.default || m)
  },
  {
    name: indexS4wCkOLP1gMeta?.name ?? "home",
    path: indexS4wCkOLP1gMeta?.path ?? "/home",
    meta: indexS4wCkOLP1gMeta || {},
    alias: indexS4wCkOLP1gMeta?.alias || [],
    redirect: indexS4wCkOLP1gMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: indexTQT7JJ2DrPMeta?.name ?? "index",
    path: indexTQT7JJ2DrPMeta?.path ?? "/",
    meta: indexTQT7JJ2DrPMeta || {},
    alias: indexTQT7JJ2DrPMeta?.alias || [],
    redirect: indexTQT7JJ2DrPMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFIZe6AGUNFMeta?.name ?? "liste-realisation",
    path: indexFIZe6AGUNFMeta?.path ?? "/liste-realisation",
    meta: indexFIZe6AGUNFMeta || {},
    alias: indexFIZe6AGUNFMeta?.alias || [],
    redirect: indexFIZe6AGUNFMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/liste-realisation/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qlbUFD58DXMeta?.name ?? "listes-produits-idMenu-idSubMenu-id",
    path: _91id_93qlbUFD58DXMeta?.path ?? "/listes-produits/:idMenu()/:idSubMenu()/:id()",
    meta: _91id_93qlbUFD58DXMeta || {},
    alias: _91id_93qlbUFD58DXMeta?.alias || [],
    redirect: _91id_93qlbUFD58DXMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/listes-produits/[idMenu]/[idSubMenu]/[id].vue").then(m => m.default || m)
  },
  {
    name: login0YQdZl3ZkwMeta?.name ?? "login",
    path: login0YQdZl3ZkwMeta?.path ?? "/login",
    meta: login0YQdZl3ZkwMeta || {},
    alias: login0YQdZl3ZkwMeta?.alias || [],
    redirect: login0YQdZl3ZkwMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesWxa0eGVjbRMeta?.name ?? "mentions-legales",
    path: mentions_45legalesWxa0eGVjbRMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesWxa0eGVjbRMeta || {},
    alias: mentions_45legalesWxa0eGVjbRMeta?.alias || [],
    redirect: mentions_45legalesWxa0eGVjbRMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: mobile_45app9yo4e6HQ5wMeta?.name ?? "mobile-app",
    path: mobile_45app9yo4e6HQ5wMeta?.path ?? "/mobile-app",
    meta: mobile_45app9yo4e6HQ5wMeta || {},
    alias: mobile_45app9yo4e6HQ5wMeta?.alias || [],
    redirect: mobile_45app9yo4e6HQ5wMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/mobile-app.vue").then(m => m.default || m)
  },
  {
    name: _91idSlug_93785FJH4UEvMeta?.name ?? "nos-domaines-slug-idSlug",
    path: _91idSlug_93785FJH4UEvMeta?.path ?? "/nos-domaines/:slug()/:idSlug()",
    meta: _91idSlug_93785FJH4UEvMeta || {},
    alias: _91idSlug_93785FJH4UEvMeta?.alias || [],
    redirect: _91idSlug_93785FJH4UEvMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/nos-domaines/[slug]/[idSlug].vue").then(m => m.default || m)
  },
  {
    name: note_45takingCzvdPosJPpMeta?.name ?? "note-taking",
    path: note_45takingCzvdPosJPpMeta?.path ?? "/note-taking",
    meta: note_45takingCzvdPosJPpMeta || {},
    alias: note_45takingCzvdPosJPpMeta?.alias || [],
    redirect: note_45takingCzvdPosJPpMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/note-taking.vue").then(m => m.default || m)
  },
  {
    name: nous_45contacterAKqWNligoOMeta?.name ?? "nous-contacter",
    path: nous_45contacterAKqWNligoOMeta?.path ?? "/nous-contacter",
    meta: nous_45contacterAKqWNligoOMeta || {},
    alias: nous_45contacterAKqWNligoOMeta?.alias || [],
    redirect: nous_45contacterAKqWNligoOMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/nous-contacter.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsWcxwQ3IIDwMeta?.name ?? "portfolio-details-dynamic-details",
    path: dynamic_45detailsWcxwQ3IIDwMeta?.path ?? "/portfolio-details/dynamic-details",
    meta: dynamic_45detailsWcxwQ3IIDwMeta || {},
    alias: dynamic_45detailsWcxwQ3IIDwMeta?.alias || [],
    redirect: dynamic_45detailsWcxwQ3IIDwMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/portfolio-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexrSHqSWQYKpMeta?.name ?? "portfolio-details",
    path: indexrSHqSWQYKpMeta?.path ?? "/portfolio-details",
    meta: indexrSHqSWQYKpMeta || {},
    alias: indexrSHqSWQYKpMeta?.alias || [],
    redirect: indexrSHqSWQYKpMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/portfolio-details/index.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v1l27TdE3fb3Meta?.name ?? "portfolio-v1",
    path: portfolio_45v1l27TdE3fb3Meta?.path ?? "/portfolio-v1",
    meta: portfolio_45v1l27TdE3fb3Meta || {},
    alias: portfolio_45v1l27TdE3fb3Meta?.alias || [],
    redirect: portfolio_45v1l27TdE3fb3Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v1.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v25VvsgAbYF6Meta?.name ?? "portfolio-v2",
    path: portfolio_45v25VvsgAbYF6Meta?.path ?? "/portfolio-v2",
    meta: portfolio_45v25VvsgAbYF6Meta || {},
    alias: portfolio_45v25VvsgAbYF6Meta?.alias || [],
    redirect: portfolio_45v25VvsgAbYF6Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v2.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v3fbxNItJfvtMeta?.name ?? "portfolio-v3",
    path: portfolio_45v3fbxNItJfvtMeta?.path ?? "/portfolio-v3",
    meta: portfolio_45v3fbxNItJfvtMeta || {},
    alias: portfolio_45v3fbxNItJfvtMeta?.alias || [],
    redirect: portfolio_45v3fbxNItJfvtMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v3.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v4wJSbZDBOEQMeta?.name ?? "portfolio-v4",
    path: portfolio_45v4wJSbZDBOEQMeta?.path ?? "/portfolio-v4",
    meta: portfolio_45v4wJSbZDBOEQMeta || {},
    alias: portfolio_45v4wJSbZDBOEQMeta?.alias || [],
    redirect: portfolio_45v4wJSbZDBOEQMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v4.vue").then(m => m.default || m)
  },
  {
    name: portfolio_45v5gzTeLvNEyjMeta?.name ?? "portfolio-v5",
    path: portfolio_45v5gzTeLvNEyjMeta?.path ?? "/portfolio-v5",
    meta: portfolio_45v5gzTeLvNEyjMeta || {},
    alias: portfolio_45v5gzTeLvNEyjMeta?.alias || [],
    redirect: portfolio_45v5gzTeLvNEyjMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/portfolio-v5.vue").then(m => m.default || m)
  },
  {
    name: pricing_45csNu4gBT8ntwMeta?.name ?? "pricing-cs",
    path: pricing_45csNu4gBT8ntwMeta?.path ?? "/pricing-cs",
    meta: pricing_45csNu4gBT8ntwMeta || {},
    alias: pricing_45csNu4gBT8ntwMeta?.alias || [],
    redirect: pricing_45csNu4gBT8ntwMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/pricing-cs.vue").then(m => m.default || m)
  },
  {
    name: pricing_45eventLQ4yO5s868Meta?.name ?? "pricing-event",
    path: pricing_45eventLQ4yO5s868Meta?.path ?? "/pricing-event",
    meta: pricing_45eventLQ4yO5s868Meta || {},
    alias: pricing_45eventLQ4yO5s868Meta?.alias || [],
    redirect: pricing_45eventLQ4yO5s868Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/pricing-event.vue").then(m => m.default || m)
  },
  {
    name: pricing_45managementmtOFifUZtyMeta?.name ?? "pricing-management",
    path: pricing_45managementmtOFifUZtyMeta?.path ?? "/pricing-management",
    meta: pricing_45managementmtOFifUZtyMeta || {},
    alias: pricing_45managementmtOFifUZtyMeta?.alias || [],
    redirect: pricing_45managementmtOFifUZtyMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/pricing-management.vue").then(m => m.default || m)
  },
  {
    name: product_45csqBc5KKaciaMeta?.name ?? "product-cs",
    path: product_45csqBc5KKaciaMeta?.path ?? "/product-cs",
    meta: product_45csqBc5KKaciaMeta || {},
    alias: product_45csqBc5KKaciaMeta?.alias || [],
    redirect: product_45csqBc5KKaciaMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/product-cs.vue").then(m => m.default || m)
  },
  {
    name: product_45landing_45darkq2Kcckcg5HMeta?.name ?? "product-landing-dark",
    path: product_45landing_45darkq2Kcckcg5HMeta?.path ?? "/product-landing-dark",
    meta: product_45landing_45darkq2Kcckcg5HMeta || {},
    alias: product_45landing_45darkq2Kcckcg5HMeta?.alias || [],
    redirect: product_45landing_45darkq2Kcckcg5HMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/product-landing-dark.vue").then(m => m.default || m)
  },
  {
    name: product_45landing3vPB71CDAVMeta?.name ?? "product-landing",
    path: product_45landing3vPB71CDAVMeta?.path ?? "/product-landing",
    meta: product_45landing3vPB71CDAVMeta || {},
    alias: product_45landing3vPB71CDAVMeta?.alias || [],
    redirect: product_45landing3vPB71CDAVMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/product-landing.vue").then(m => m.default || m)
  },
  {
    name: indexDoca8xJrUdMeta?.name ?? "savoir-faire",
    path: indexDoca8xJrUdMeta?.path ?? "/savoir-faire",
    meta: indexDoca8xJrUdMeta || {},
    alias: indexDoca8xJrUdMeta?.alias || [],
    redirect: indexDoca8xJrUdMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/savoir-faire/index.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsFkGfcIiu4VMeta?.name ?? "service-details-dynamic-details",
    path: dynamic_45detailsFkGfcIiu4VMeta?.path ?? "/service-details/dynamic-details",
    meta: dynamic_45detailsFkGfcIiu4VMeta || {},
    alias: dynamic_45detailsFkGfcIiu4VMeta?.alias || [],
    redirect: dynamic_45detailsFkGfcIiu4VMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/service-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: index5BndF7fKLCMeta?.name ?? "service-details",
    path: index5BndF7fKLCMeta?.path ?? "/service-details",
    meta: index5BndF7fKLCMeta || {},
    alias: index5BndF7fKLCMeta?.alias || [],
    redirect: index5BndF7fKLCMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/service-details/index.vue").then(m => m.default || m)
  },
  {
    name: services_45v1vyhHjmLLSZMeta?.name ?? "services-v1",
    path: services_45v1vyhHjmLLSZMeta?.path ?? "/services-v1",
    meta: services_45v1vyhHjmLLSZMeta || {},
    alias: services_45v1vyhHjmLLSZMeta?.alias || [],
    redirect: services_45v1vyhHjmLLSZMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/services-v1.vue").then(m => m.default || m)
  },
  {
    name: services_45v2ysQhYDxv8iMeta?.name ?? "services-v2",
    path: services_45v2ysQhYDxv8iMeta?.path ?? "/services-v2",
    meta: services_45v2ysQhYDxv8iMeta || {},
    alias: services_45v2ysQhYDxv8iMeta?.alias || [],
    redirect: services_45v2ysQhYDxv8iMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/services-v2.vue").then(m => m.default || m)
  },
  {
    name: services_45v3iHlRfBJjGNMeta?.name ?? "services-v3",
    path: services_45v3iHlRfBJjGNMeta?.path ?? "/services-v3",
    meta: services_45v3iHlRfBJjGNMeta || {},
    alias: services_45v3iHlRfBJjGNMeta?.alias || [],
    redirect: services_45v3iHlRfBJjGNMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/services-v3.vue").then(m => m.default || m)
  },
  {
    name: services_45v4P9gWNscdd2Meta?.name ?? "services-v4",
    path: services_45v4P9gWNscdd2Meta?.path ?? "/services-v4",
    meta: services_45v4P9gWNscdd2Meta || {},
    alias: services_45v4P9gWNscdd2Meta?.alias || [],
    redirect: services_45v4P9gWNscdd2Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/services-v4.vue").then(m => m.default || m)
  },
  {
    name: sign_45up6BbquDvgkjMeta?.name ?? "sign-up",
    path: sign_45up6BbquDvgkjMeta?.path ?? "/sign-up",
    meta: sign_45up6BbquDvgkjMeta || {},
    alias: sign_45up6BbquDvgkjMeta?.alias || [],
    redirect: sign_45up6BbquDvgkjMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: solution_45managementooMRDxVKTGMeta?.name ?? "solution-management",
    path: solution_45managementooMRDxVKTGMeta?.path ?? "/solution-management",
    meta: solution_45managementooMRDxVKTGMeta || {},
    alias: solution_45managementooMRDxVKTGMeta?.alias || [],
    redirect: solution_45managementooMRDxVKTGMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/solution-management.vue").then(m => m.default || m)
  },
  {
    name: dynamic_45detailsAnzZQAysbCMeta?.name ?? "team-details-dynamic-details",
    path: dynamic_45detailsAnzZQAysbCMeta?.path ?? "/team-details/dynamic-details",
    meta: dynamic_45detailsAnzZQAysbCMeta || {},
    alias: dynamic_45detailsAnzZQAysbCMeta?.alias || [],
    redirect: dynamic_45detailsAnzZQAysbCMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/team-details/dynamic-details.vue").then(m => m.default || m)
  },
  {
    name: indexZyb0Xrd26WMeta?.name ?? "team-details",
    path: indexZyb0Xrd26WMeta?.path ?? "/team-details",
    meta: indexZyb0Xrd26WMeta || {},
    alias: indexZyb0Xrd26WMeta?.alias || [],
    redirect: indexZyb0Xrd26WMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/team-details/index.vue").then(m => m.default || m)
  },
  {
    name: team_45v1yHtBjLYoclMeta?.name ?? "team-v1",
    path: team_45v1yHtBjLYoclMeta?.path ?? "/team-v1",
    meta: team_45v1yHtBjLYoclMeta || {},
    alias: team_45v1yHtBjLYoclMeta?.alias || [],
    redirect: team_45v1yHtBjLYoclMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/team-v1.vue").then(m => m.default || m)
  },
  {
    name: team_45v2WIDAPPhrOMMeta?.name ?? "team-v2",
    path: team_45v2WIDAPPhrOMMeta?.path ?? "/team-v2",
    meta: team_45v2WIDAPPhrOMMeta || {},
    alias: team_45v2WIDAPPhrOMMeta?.alias || [],
    redirect: team_45v2WIDAPPhrOMMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/team-v2.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionOBujsoU9NJMeta?.name ?? "terms-condition",
    path: terms_45conditionOBujsoU9NJMeta?.path ?? "/terms-condition",
    meta: terms_45conditionOBujsoU9NJMeta || {},
    alias: terms_45conditionOBujsoU9NJMeta?.alias || [],
    redirect: terms_45conditionOBujsoU9NJMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/terms-condition.vue").then(m => m.default || m)
  },
  {
    name: video_45editorPemdMG9NlZMeta?.name ?? "video-editor",
    path: video_45editorPemdMG9NlZMeta?.path ?? "/video-editor",
    meta: video_45editorPemdMG9NlZMeta || {},
    alias: video_45editorPemdMG9NlZMeta?.alias || [],
    redirect: video_45editorPemdMG9NlZMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/video-editor.vue").then(m => m.default || m)
  },
  {
    name: vr_45landingj2CiTNmgL5Meta?.name ?? "vr-landing",
    path: vr_45landingj2CiTNmgL5Meta?.path ?? "/vr-landing",
    meta: vr_45landingj2CiTNmgL5Meta || {},
    alias: vr_45landingj2CiTNmgL5Meta?.alias || [],
    redirect: vr_45landingj2CiTNmgL5Meta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/vr-landing.vue").then(m => m.default || m)
  },
  {
    name: website_45builderFE4FzeE9ckMeta?.name ?? "website-builder",
    path: website_45builderFE4FzeE9ckMeta?.path ?? "/website-builder",
    meta: website_45builderFE4FzeE9ckMeta || {},
    alias: website_45builderFE4FzeE9ckMeta?.alias || [],
    redirect: website_45builderFE4FzeE9ckMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/Altela/pages/website-builder.vue").then(m => m.default || m)
  }
]